<template>
  <ckeditor v-model="editorData" :disabled="disabled" :editor="editor" :config="editorConfig" @input="$emit('input', editorData)" @ready="uploader"/>
</template>
<script>
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials';
import UploadAdapter from '@ckeditor/ckeditor5-adapter-ckfinder/src/uploadadapter';
import Autoformat from '@ckeditor/ckeditor5-autoformat/src/autoformat';
import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold';
import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic';
import BlockQuote from '@ckeditor/ckeditor5-block-quote/src/blockquote';
import CKFinder from '@ckeditor/ckeditor5-ckfinder/src/ckfinder';
import EasyImage from '@ckeditor/ckeditor5-easy-image/src/easyimage';
import Font from '@ckeditor/ckeditor5-font/src/font';
import Heading from '@ckeditor/ckeditor5-heading/src/heading';
import Image from '@ckeditor/ckeditor5-image/src/image';
import ImageCaption from '@ckeditor/ckeditor5-image/src/imagecaption';
import ImageInsert from '@ckeditor/ckeditor5-image/src/imageinsert';
import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle';
import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar';
import ImageUpload from '@ckeditor/ckeditor5-image/src/imageupload';
import ImageResize from '@ckeditor/ckeditor5-image/src/imageresize';
import ImageResizeEditing from '@ckeditor/ckeditor5-image/src/imageresize/imageresizeediting';
import ImageResizeHandles from '@ckeditor/ckeditor5-image/src/imageresize/imageresizehandles';
import Indent from '@ckeditor/ckeditor5-indent/src/indent';
import Link from '@ckeditor/ckeditor5-link/src/link';
import List from '@ckeditor/ckeditor5-list/src/list';
import MediaEmbed from '@ckeditor/ckeditor5-media-embed/src/mediaembed';
import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import PasteFromOffice from '@ckeditor/ckeditor5-paste-from-office/src/pastefromoffice';
import Table from '@ckeditor/ckeditor5-table/src/table';
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar';
import TextTransformation from '@ckeditor/ckeditor5-typing/src/texttransformation';
import CloudServices from '@ckeditor/ckeditor5-cloud-services/src/cloudservices';
import SourceEditing from '@ckeditor/ckeditor5-source-editing/src/sourceediting';
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';
import GeneralHtmlSupport from '@ckeditor/ckeditor5-html-support/src/generalhtmlsupport';

class ExtraUploadAdapter {
  constructor (loader, api) {
      // The file loader instance to use during the upload.
      this.loader = loader;
      this.api = api;
  }

  // Starts the upload process.
  upload() {
    return new Promise((resolve, reject) => 
      this.loader.file
        .then(file => this.api(file, 'ckeditor'))
        .then(res => {
          res.status === 200
            ? resolve({ default: res.data.data.url })
            : reject(res.data.message)
        }).catch(e => {
          reject(e)
          console.error(e)
        }))
  }
}


export default {
  name: 'CKEditor',
  props: ['value', 'disabled'],
  data() {
    return {
      editor: ClassicEditor,
      editorData: this.value,
      editorConfig: {
        // extraPlugins: [this.uploader],
        plugins: [
          Essentials,
          UploadAdapter,
          Autoformat,
          Bold,
          Italic,
          BlockQuote,
          CKFinder,
          CloudServices,
          EasyImage,
          Font,
          Heading,
          Image,
          ImageResize,
          ImageCaption,
          ImageInsert,
          ImageStyle,
          ImageToolbar,
          ImageUpload,
          ImageResizeEditing,
          ImageResizeHandles,
          Indent,
          Link,
          List,
          MediaEmbed,
          Paragraph,
          PasteFromOffice,
          Table,
          TableToolbar,
          TextTransformation,
          SourceEditing,
          Alignment,
          GeneralHtmlSupport,
        ],
        toolbar: {
          items: [
            'alignment:left', 'alignment:center', 'alignment:right', 'alignment:justify', '|',
            'fontSize', 'fontColor', 'fontBackgroundColor', '|',
            'bold', 'italic', '|',
            'link', '|', 
            'bulletedList', 'numberedList', '|',
            'outdent', 'indent',
            '-', // break point
            'sourceEditing', '|',
            'blockQuote', '|',
            'insertImage', 'insertTable', 'mediaEmbed', '|',
            'undo', 'redo',
          ],
          shouldNotGroupWhenFull: true // wrap toolbar item to the next line
        },
        image: {
          resizeOptions: [
            {
              name: 'resizeImage:original',
              value: null,
              label: 'Unset'
            },
            {
              name: 'resizeImage:1/12',
              value: '8.333333',
              label: '1/12'
            },
            {
              name: 'resizeImage:2/12',
              value: '16.666666',
              label: '2/12'
            },
            {
              name: 'resizeImage:3/12',
              value: '25',
              label: '3/12'
            },
            {
              name: 'resizeImage:4/12',
              value: '33.333333',
              label: '4/12'
            },
            {
              name: 'resizeImage:5/12',
              value: '41.666666',
              label: '5/12'
            },
            {
              name: 'resizeImage:6/12',
              value: '50',
              label: '6/12'
            },
            {
              name: 'resizeImage:7/12',
              value: '58.333333',
              label: '7/12'
            },
            {
              name: 'resizeImage:8/12',
              value: '66.666666',
              label: '8/12'
            },
            {
              name: 'resizeImage:9/12',
              value: '75',
              label: '9/12'
            },
            {
              name: 'resizeImage:10/12',
              value: '83.333333',
              label: '10/12'
            },
            {
              name: 'resizeImage:11/12',
              value: '91.666666',
              label: '11/12'
            },
            {
              name: 'resizeImage:12/12',
              value: '100',
              label: '12/12'
            },
          ],
          toolbar: [
            'imageStyle:inline',
            'imageStyle:block',
            'imageStyle:side',
            'imageStyle:full',
            '|',
            'toggleImageCaption',
            'imageTextAlternative',
            'resizeImage'
          ]
        },
        table: {
          contentToolbar: [
            'tableColumn',
            'tableRow',
            'mergeTableCells'
          ]
        },
        fontSize: {
          options: [
              10,
              11,
              12,
              14,
              'default',
              18,
              20,
              22,
              24,
              26,
              28,
          ],
          supportAllValues: true,
        },
        language: 'en',
        htmlSupport: {
          allow: [
            {
              styles: true
            },
            {
              name: "iframe",
              styles: true,
              attributes: true
            }
          ],
        }
      },
    }
  },
  methods: {
    uploader(editor) {
      editor.plugins.get('FileRepository').createUploadAdapter = (loader) => new ExtraUploadAdapter(loader, this.$api.media.upload)
    }
  },
  watch: {
    value (newVal) {
      this.editorData = newVal ?? ""
    }
  }
}
</script>
<style>
.ck.ck-toolbar.ck-toolbar_grouping>.ck-toolbar__items {
  flex-wrap: wrap;
}
.ck-editor__editable {
  min-height: 128px;
}
</style>
